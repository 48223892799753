export const routeLinks = {
  auth: {
    login: '/auth/login',
    register: '/auth/register',
    two_fa: '/auth/two_fa',
    enter_otp: '/auth/enter_otp',
    confirm_otp: '/auth/confirm_otp',
    forgot_password: '/auth/forgot_password',
    reset_password: '/auth/reset_pasword',
    reset_password_successful: '/auth/reset_password_successful',
    external_payment_link: '/b2b',
  },
  protected: {
    compliance: {
      index: '/compliance',
      kyc: '/compliance/kyc',
      wallet: '/compliance/wallet',
    },
    overview: '/',
    wallets: {
      index: '/wallet',
      fiat: {
        index: '/wallet/fiat',
        debit: '/wallet/fiat/debit',
        credit: '/wallet/fiat/credit',
        api_keys: '/wallet/fiat/api-keys',
      },
      crypto: {
        index: '/wallet/crypto',
        withdrawal: '/wallet/crypto/withdrawal',
        deposit: '/wallet/crypto/deposit',
        address_book: '/wallet/crypto/address-book',
      },
      kyc: {
        index: '/wallet/kyc',
        debit: '/wallet/kyc/debit',
        deposit: '/wallet/kyc/deposit',
      },
    },
    transactions: {
      index: '/transactions',
      fiat: {
        index: '/transactions/fiat',
        collections: '/transactions/fiat/collections',
        payout: '/transactions/fiat/payout',
      },
      crypto: {
        index: '/transactions/crypto',
        capital: '/transactions/crypto/capital',
        commission: '/transactions/crypto/commission',
      },
    },
    payment: {
      index: '/payment',
      swap: {
        index: '/payment/swap',
        records: '/payment/swap/records',
      },
      send: '/payment/send',
      links: '/payment/links',
      address_book: {
        list: '/payment/address-book',
        create: '/payment/address-book/create',
      },
      virtual_card: {
        index: '/payment/virtual-card',
        create: '/payment/virtual-card/create',
      },
    },
    settings: {
      index: '/settings',
      reset_password: '/settings/reset-password',
      language_settings: '/settings/language-settings',
    },
  },
};
